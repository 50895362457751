export default [
  {
    id: 'a',
    display: 'A',
    displaySub: '',
    bed: 1,
    type: '1 BED + 1 BATH',
    sqft: '547 - 651',
    levels: ['2', '3', '4', '5'],
  },
  {
    id: 'b2',
    display: 'B2',
    displaySub: '',
    bed: 1,
    type: '1 BED + 1 BATH',
    sqft: '616 - 619',
    levels: ['3', '4', '5'],
  },
  {
    id: 'd',
    display: 'D',
    displaySub: '',
    bed: 1,
    type: '1 BED & DEN + 1 BATH',
    sqft: '671 - 792',
    levels: ['3', '4', '5'],
  },
  {
    id: 'e',
    display: 'E',
    displaySub: '',
    bed: 1,
    type: '1 BED & DEN + 1 BATH',
    sqft: '787',
    levels: ['3', '4', '5'],
  },
  // {
  //   id: 'f',
  //   display: 'F',
  //   displaySub: '',
  //   type: 'STUDIO + 1 BATH',
  //   bed: 0,
  //   sqft: '653',
  //   levels: ['1'],
  // },
  {
    id: 'g',
    display: 'G',
    displaySub: '',
    type: 'STUDIO + 1 BATH',
    bed: 0,
    sqft: '609',
    levels: ['2', '3', '4', '5'],
  },
  {
    id: 'k',
    display: 'K',
    displaySub: '',
    type: '2 BED + 1 BATH',
    bed: 2,
    sqft: '858 - 903',
    levels: ['1', '2', '3', '4'],
  },
  {
    id: 'o',
    display: 'O',
    displaySub: '',
    type: '3 BED + 2 BATH',
    bed: 3,
    sqft: '1,012 - 1,415',
    levels: ['3', '4', '5'],
  },
  {
    id: 'p',
    display: 'P',
    displaySub: '',
    type: '3 BED + 2 BATH',
    bed: 3,
    sqft: '941 - 986',
    levels: ['2', '3', '4', '5'],
  },
  {
    id: 's',
    display: 'S',
    displaySub: '',
    type: '2 BED + 1 BATH',
    bed: 2,
    sqft: '1,046',
    levels: ['6'],
  },
  // {
  //   id: 'w',
  //   display: 'W',
  //   displaySub: '',
  //   type: '2 BED + 2 BATH',
  //   bed: 2,
  //   sqft: '958',
  //   levels: ['5'],
  // },
  // {
  //   id: 'x',
  //   display: 'x',
  //   displaySub: '',
  //   type: '2 BED + 1 BATH',
  //   bed: 2,
  //   sqft: '858',
  //   levels: ['5'],
  // },

  {
    id: 'c2',
    display: 'C2',
    displaySub: '',
    bed: 1,
    type: '1 BED + 1 BATH',
    sqft: '568 - 667',
    levels: ['1', '2', '3', '4'],
  },
  {
    id: 'j',
    display: 'J',
    displaySub: '',
    bed: 3,
    type: '3 BED + 2 BATH',
    sqft: '961 - 983',
    levels: ['1', '2', '3', '4'],
  },
  {
    id: 'm',
    display: 'M',
    displaySub: '',
    bed: 2,
    type: '2 BED + 2 BATH',
    sqft: '838',
    levels: ['1'],
  },
  {
    id: 'r',
    display: 'R',
    displaySub: '',
    bed: 1,
    type: '1 BED + 1 BATH',
    sqft: '570',
    levels: ['3', '4', '5'],
  },
  {
    id: 't',
    display: 'T',
    displaySub: '',
    bed: 2,
    type: '2 BED + 2 BATH',
    sqft: '842',
    levels: ['6'],
  },
];
